<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BRow>
		<BCol class="text-center mt-5">
			<FontAwesomeIcon
				class="modal-icon mb-3"
				:icon="['fal', 'exclamation-triangle']"
				aria-hidden="true"
			/>
			<h1>{{ $t('text1') }}</h1>
			<p class="josefin mt-3 mb-4">{{ $t('text2') }}</p>
			<BaseButton
				variant="primary"
				:label="$t('loginButton')"
				automation-id="not-found"
				@click="toLogin"
			/>
		</BCol>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'NotFound',
	components: {
		BRow,
		BCol,
		BaseButton
	}
})
export default class NotFound extends Vue {
	toLogin() {
		if (sessionStorage.getItem('loggedIn')) {
			if (this.$router.currentRoute.name !== Routes.DASHBOARD) {
				this.$router.push({ name: Routes.DASHBOARD });
			}
		} else {
			if (this.$router.currentRoute.name !== Routes.LOGIN) {
				this.$router.push({ name: Routes.LOGIN });
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-icon {
	font-size: 42px;
	& ::v-deep path {
		color: $orange-dark;
	}
}
.josefin {
	font-family: $josefin-font-family;
	font-size: 21px;
}
</style>

<i18n>
{
	"en": {
		"text1": "Oops! That wasn't supposed to happen.",
		"text2": "Let's get you back on track. ",
		"loginButton": "Return to home"
	},
	"fr": {
		"text1": "Oups! Un imprévu est survenu.",
		"text2": "Laissez-nous vous guider vers le bon endroit.",
		"loginButton": "Retourner à l'accueil"
	}
}
</i18n>
